import React from "react";
import styled from "styled-components";
import colors from "../../common/colors";
import convertDotSpaceToLineBreak from "../../common/convertDotSpaceToLineBreak";
import slugify from "../../utils/slugify";
import { DelayBadge } from "../Badge";

// icons:
import { ReactComponent as CarsIcon } from "../../common/icons/cars.svg";
import { ReactComponent as IncidentIcon } from "../../common/icons/incident.svg";
import { ReactComponent as RoadworksIcon } from "../../common/icons/roadwork.svg";
import { ReactComponent as BridgeOpeningIcon } from "../../common/icons/bridge.svg";
import formatDirection from "../../common/formatDirection";
import formatPeriod from "../../common/formatPeriod";
import { hindranceToDelay } from "../../pages/Obstruction";

import ListCard from "../List/Card";

const Route = styled.div`
  font-size: 14px;
  line-height: 1.35;
`;

const Location = styled.div`
  font-size: 13px;
  color: ${colors.gray700};
  line-height: 1.25;
`;

const getColorFromDelay = delay => {
  if (delay > 30) {
    return "red";
  }
  if (delay > 10) {
    return "orange";
  }
  return "yellow";
};

const getObstructionIcon = type => {
  switch (type) {
    case 1: // 1 - Roadworks,
      return <RoadworksIcon />;
    case 2: // 2 - Incident,
    case 3: // 3 - Weather,
      return <IncidentIcon />;
    case 4: // 4 - Trafficjam,
      return <CarsIcon />;
    case 5: // 5 - VehicleObstruction, -> "Hulpdienstvoertuigen"
    case 6: // 6 - Omleiding,
    case 7: // 7 - Closures,
    case 8: // 8 - SafetyRelated,
    case 9: // 9 - AnimalPresence,
    case 10: // 10 - InfrastructureDamage,
    case 11: // 11 - Environmental,
    case 12: // 12 - Tweet,
    case 13: // 13 - AuthorityOperation,
    case 14: // 14 - DisturbanceActivity,
    case 15: // 15 - PublicEvent,
    case 16: // 16 - NonWeatherRelatedRoadCondition,
    case 17: // 17 - WeatherRelatedRoadCondition,
    case 18: // 18 - GeneralInstructionOrMessageForRoadusers,
    case 19: // 19 - SpeedManagement,
    case 20: // 20 - GeneralObstruction, -> "voorwerpen op de weg"
    case 21: // 21 - VehicleObstructionBrokenDownOnda -> "pechgeval"
    case 22: // 22 - Unknown, undocumented in ibera api docs
      return <IncidentIcon />;
    case 23: // 23 - Bridgeopening, undocumented in ibera api docs
      return <BridgeOpeningIcon />;
    default:
      return <IncidentIcon />;
  }
};

function ObstructionCard(props) {
  const isIncidentOrRoadClosure = [2, 7].includes(props.obstructionType);

  return (
    <ListCard
      to={`/hinder/${props.obstructionId}/${slugify(props.directionText)}`}
      icon={getObstructionIcon(props.obstructionType)}
      roadNumber={props.roadNumber}
      title={props.title}
      onClick={props.onClick}
      ref={props.forwardedRef}
    >
      <Route>
        {formatDirection(props)}{" "}
        {props.delay && !isIncidentOrRoadClosure ? (
          <DelayBadge color={getColorFromDelay(props.delay)}>{props.delay} min</DelayBadge>
        ) : null}
      </Route>
      <Location>
        {convertDotSpaceToLineBreak(
          props.obstructionType === 3 ? props.cause : props.locationText,
        )}
        {Boolean(props.obstructionType === 1) && (
          <>
            <br />
            Verwachte hinder: {hindranceToDelay(props.hindrance)}
            <br />
            Periode: {formatPeriod(props.timeStart, props.timeEnd)}
          </>
        )}
      </Location>
    </ListCard>
  );
}

export default React.forwardRef((props, ref) => <ObstructionCard {...props} forwardedRef={ref} />);

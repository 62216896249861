import React from "react";
import styled, { css } from "styled-components";
import colors from "../../common/colors";
import { mq } from "../../common/utils";
import iconTwitter from "./icon-twitter-x.svg";
import iconYouTube from "./icon-youtube.svg";
import { Link as RouterLink } from "react-router-dom";

const Navigation = styled.div`
  padding: 2.125rem 1rem;
`;

const Icon = styled.img`
  margin-top: 1rem;
`;

const Title = styled.h4`
  margin-bottom: 1rem;
  margin-top: 0;
  font-size: 20px;
  font-weight: bold;
  color: ${colors.gray700};
`;

const Nav = styled.nav`
  display: flex;
  flex-wrap: wrap;
`;

const IconWrapper = styled.div``;

const FooterWrapper = styled.footer`
  background-color: ${colors.gray100};
  margin-top: auto;
  ${props =>
    props.fullWidth &&
    css`
      ${Navigation} {
        ${mq("tablet")`
        padding: 0 1rem;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        height: 104px;
      `}
      }
      ${Icon} {
        ${mq("tablet")`
        margin-top: 0;
      `}
      }
      ${IconWrapper} {
        ${mq("tablet")`
        display: flex;
        justify-content: flex-end;
        align-items: center;
      `}
      }
      ${Title} {
        ${mq("tablet")`
        display: flex;
        align-items: center;
        margin-bottom: 0;
      `}
      }
      ${Nav} {
        ${mq("tablet")`
        display: flex;
        justify-content: center;
        align-items: center;
      `}
      }
    `}
`;

const PreFooter = styled.div`
  background-color: ${colors.white};
`;

const BlueBar = styled.div`
  background-color: ${colors.logoblauw};
  width: 2.5rem;
  height: 24px;
  margin: 0 auto;
`;

const Link = styled.a.attrs(
  props =>
    props.externalLink && {
      target: "_blank",
      rel: "noreferrer nofollow",
    },
)`
  text-decoration: none;
  margin-right: 10px;
  color: ${colors.gray700};
  font-size: 14px;
  &:hover {
    color: ${colors.gray900};
  }
  ${props =>
    props.smallMargin &&
    css`
      margin-right: 8px;
    `}
`;

function Footer(props) {
  return (
    <FooterWrapper fullWidth={props.fullWidth}>
      <PreFooter>
        <BlueBar />
      </PreFooter>
      <Navigation>
        <Title as={props.titleElement}>Rijkswaterstaat Verkeersinfo</Title>
        <Nav>
          <Link title="contact" to="/contact/" as={RouterLink}>
            Contact
          </Link>
          <Link title="sitemap" to="/sitemap/" as={RouterLink}>
            Sitemap
          </Link>
          <Link externalLink title="privacy" href="https://www.rijkswaterstaat.nl/privacy">
            Privacy
          </Link>
          <Link externalLink title="cookies" href="https://www.rijkswaterstaat.nl/cookies">
            Cookies
          </Link>
          <Link externalLink title="Rijkswaterstaat.nl" href="https://www.rijkswaterstaat.nl">
            Rijkswaterstaat.nl
          </Link>
        </Nav>
        <IconWrapper>
          <Link smallMargin href="https://x.com/rwsverkeersinfo">
            <Icon src={iconTwitter} alt="@rwsverkeersinfo op X" />
          </Link>
          <Link smallMargin href="https://www.youtube.com/channel/UCp1ocKb49vFchuQ8CiQPSgg">
            <Icon src={iconYouTube} alt="Rijkswaterstaat op Youtube" />
          </Link>
        </IconWrapper>
      </Navigation>
    </FooterWrapper>
  );
}

Footer.defaultProps = {
  titleElement: "div",
};

export default Footer;

import React from "react";
import styled, { css } from "styled-components";
import colors from "../common/colors";
import { mq } from "../common/utils";
import { ReactComponent as Camera } from "../common/icons/camera.svg";
import { ReactComponent as SpotifyLogo } from "../common/icons/spotify-logo.svg";
import { ReactComponent as TwitterLogo } from "../common/icons/twitter-x-logo.svg";
import { ReactComponent as MastodonLogo } from "../common/icons/mastodon-logo.svg";
import { ReactComponent as YoutubeLogo } from "../common/icons/youtube-logo.svg";
import { ReactComponent as SpreaderTruck } from "../common/icons/spreader-truck.svg";
import { Link as RouterLink } from "react-router-dom";

const Wrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
  padding: 0.5rem 1rem;
  display: none;
  ${mq("desktop")`
    display: flex;
  `}
  ${props =>
    props.$mobile &&
    css`
      position: relative;
      display: block;
      padding: 0;
      ${mq("desktop")`
        display: block;
      `}
    `}
`;

const linkStyle = css`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${colors.logoblauw};
  margin-left: 0.8rem;
  font-size: 0.9rem;
  svg {
    height: 20px;
    margin-right: 0.3rem;
  }
  &:focus,
  &:hover {
    color: ${colors.hemelsblauw};
  }
  display: none;
  ${mq("desktop")`
    display: flex;
  `}
  ${props =>
    props.$mobile &&
    css`
      display: flex;
      margin-bottom: 1rem;
      svg {
        margin-right: 0.5rem;
      }
    `}
`;

const Link = styled(RouterLink)`
  ${linkStyle}
`;

const ExternalLink = styled.a.attrs(() => ({
  as: "a",
  target: "_blank",
  rel: "noreferrer nofollow",
}))`
  ${linkStyle}
`;

const IconWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isMobile'].includes(prop),
})`
  display: flex;
  align-items: center;
  ${props =>
    props.isMobile &&
    css`
      width: 30px;
    `}
`;

const links = [
  {
    icon: SpotifyLogo,
    text: "Podcast",
    url: "https://open.spotify.com/show/0TuoKqjmFIO3yGUOWQw61U",
    isExternal: true,
    iconColor: "#1DB954",
    iconWidth: "16px",
  },
  {
    icon: YoutubeLogo,
    text: "Video",
    url: "https://www.youtube.com/channel/UCp1ocKb49vFchuQ8CiQPSgg",
    isExternal: true,
    iconColor: "#FF0000",
    iconWidth: "16px",
  },
  {
    icon: TwitterLogo,
    text: "X",
    url: "https://x.com/rwsverkeersinfo",
    isExternal: true,
    iconColor: "#1DA1F2",
    iconWidth: "16px",
  },
  // {
  //   icon: MastodonLogo,
  //   text: "Mastodon",
  //   url: "https://social.overheid.nl/@RWSverkeersinfo",
  //   isExternal: true,
  //   iconColor: "#6364FF",
  //   iconWidth: "16px",
  // },
  {
    icon: Camera,
    text: "Camera's",
    url: "/cameras/",
    iconColor: colors.logoblauw,
    iconWidth: "23px",
  },
  {
    icon: SpreaderTruck,
    text: "Strooikaart",
    url: "https://rijkswaterstaatstrooit.nl",
    isExternal: true,
    iconColor: colors.logoblauw,
    iconWidth: "25px",
  },
];

function MenuLink({ isExternal, url, isMobile, children, ...rest }) {
  if (isExternal) {
    return (
      <ExternalLink href={url} $mobile={isMobile} {...rest}>
        {children}
      </ExternalLink>
    );
  }
  return (
    <Link to={url} $mobile={isMobile} {...rest}>
      {children}
    </Link>
  );
}

function SecondaryMenu(props) {
  return (
    <Wrapper $mobile={props.mobileMenu}>
      {links.map(link => {
        const Icon = link.icon;

        const iconStyle = { color: link.iconColor, width: link.iconWidth };
        return (
          <MenuLink
            url={link.url}
            isExternal={link.isExternal}
            isMobile={props.mobileMenu}
            key={link.text}
            tabIndex={props.disableKeyboardAccess ? -1 : undefined}
          >
            <IconWrapper isMobile={props.mobileMenu}>
              <Icon style={iconStyle} />
            </IconWrapper>
            {link.text}
          </MenuLink>
        );
      })}
    </Wrapper>
  );
}

export default SecondaryMenu;
